
jQuery(document).ready(function($) {
    if ($('.accordion').length) {
        var allPanels = $('.accordion dd').hide();

        $('.accordion_item').click(function(event) {
            event.preventDefault();

            var accordionItem = $(this);

            if (accordionItem.hasClass('active')) {
                accordionItem.removeClass('active');
                accordionItem.find('dd').stop(true, true).slideUp(600);
            } else {
                $('.accordion_item').removeClass('active');
                $('.accordion dt > a').removeClass('active');
                allPanels.stop(true, true).slideUp(600);

                accordionItem.addClass('active');
                accordionItem.find('dd').stop(true, true).slideDown(600);
            }
        });
    }
});