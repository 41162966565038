document.addEventListener('DOMContentLoaded', function () {
    const contactForm = document.querySelector('section.contact-form');

    if (!contactForm) {
        return;
    }

    const form = contactForm.querySelector('form');
    const emailInput = form.querySelector('input[type="email"]');
    const formRedirect = form.dataset.redirect;

    if (formRedirect) {
        emailInput.addEventListener('change', function () {
            const emailValue = emailInput.value.trim();
            const redirectURL = formRedirect + `?email=${encodeURIComponent(emailValue)}`;
            form.dataset.redirect = redirectURL;

            console.log('Redirect URL:', redirectURL);
        });
    }

    // Accordion functionality
    const accordionButtons = document.querySelectorAll('.accordion-activator');
    
    if (accordionButtons.length) {
        accordionButtons.forEach(button => {
            button.addEventListener('click', function() {
                // Toggle active class on the button
                this.classList.toggle('active');
                
                // Get the accordion content
                const content = this.nextElementSibling;
                
                // Toggle the accordion content visibility
                if (content.style.maxHeight) {
                    content.style.maxHeight = null;
                } else {
                    content.style.maxHeight = content.scrollHeight + "px";
                }
                
                // Optional: Close other accordions
                accordionButtons.forEach(otherButton => {
                    if (otherButton !== button) {
                        otherButton.classList.remove('active');
                        const otherContent = otherButton.nextElementSibling;
                        if (otherContent.style.maxHeight) {
                            otherContent.style.maxHeight = null;
                        }
                    }
                });
            });
        });
        
        // Open first accordion by default (optional)
        // if (accordionButtons.length > 0) {
        //     accordionButtons[0].click();
        // }
    }
});