/**
 * Newsletter Modal - Accessible Modal Implementation
 */
(function() {
  // Cache DOM elements
  const modal = document.getElementById('newsletter-modal');
  const triggers = document.querySelectorAll('.newsletter-activate');
  const closeButtons = modal.querySelectorAll('[data-close]');
  
  // Store the element that was focused before the modal was opened
  let previouslyFocused = null;
  
  // Keep track of the first and last focusable elements in the modal
  let focusableElements = null;
  let firstFocusableElement = null;
  let lastFocusableElement = null;
  
  /**
   * Get all focusable elements in the modal
   */
  function getFocusableElements() {
    return Array.from(
      modal.querySelectorAll('a[href], button, textarea, input, select, [tabindex]:not([tabindex="-1"])')
    ).filter(el => !el.hasAttribute('disabled'));
  }
  
  /**
   * Open the modal
   */
  function openModal() {
    // Store the currently focused element
    previouslyFocused = document.activeElement;
    
    // Show the modal
    modal.classList.add('is-entering');
    modal.classList.add('is-open');
    
    // Update ARIA attributes
    modal.setAttribute('aria-hidden', 'false');
    
    // Get all focusable elements
    focusableElements = getFocusableElements();
    firstFocusableElement = focusableElements[0];
    lastFocusableElement = focusableElements[focusableElements.length - 1];
    
    // Set focus on the first focusable element
    setTimeout(() => {
      firstFocusableElement.focus();
      modal.classList.remove('is-entering');
    }, 50);
    
    // Add event listeners
    document.addEventListener('keydown', handleKeyDown);
    document.body.classList.add('modal-open');
  }
  
  /**
   * Close the modal
   */
  function closeModal() {
    // Hide the modal with transition
    modal.classList.add('is-leaving');
    
    setTimeout(() => {
      modal.classList.remove('is-open');
      modal.classList.remove('is-leaving');
      
      // Update ARIA attributes
      modal.setAttribute('aria-hidden', 'true');
      
      // Restore focus to the previously focused element
      if (previouslyFocused) {
        previouslyFocused.focus();
      }
      
      // Remove event listeners
      document.removeEventListener('keydown', handleKeyDown);
      document.body.classList.remove('modal-open');
    }, 300);
  }
  
  /**
   * Handle keyboard events
   */
  function handleKeyDown(event) {
    // Close on escape key
    if (event.key === 'Escape') {
      closeModal();
      return;
    }
    
    // Trap focus within the modal
    if (event.key === 'Tab') {
      if (event.shiftKey) { // Shift + Tab
        if (document.activeElement === firstFocusableElement) {
          event.preventDefault();
          lastFocusableElement.focus();
        }
      } else { // Tab
        if (document.activeElement === lastFocusableElement) {
          event.preventDefault();
          firstFocusableElement.focus();
        }
      }
    }
  }
  
  /**
   * Click handler for outside the modal
   */
  function handleOverlayClick(event) {
    if (event.target.classList.contains('modal-overlay')) {
      closeModal();
    }
  }
  
  // Initialize event listeners
  function init() {
    // Add click event listeners to triggers
    triggers.forEach(trigger => {
      trigger.addEventListener('click', function(event) {
        event.preventDefault();
        openModal();
      });
    });
    
    // Add click event listeners to close buttons
    closeButtons.forEach(button => {
      button.addEventListener('click', closeModal);
    });
    
    // Add click event listener to overlay for closing modal
    modal.addEventListener('click', handleOverlayClick);
  }
  
  // Initialize when DOM is ready
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
