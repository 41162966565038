document.addEventListener("DOMContentLoaded", function () {
    const VATToggleEl = document.getElementById("vat-toggle");
    const priceAmountEls = document.querySelectorAll(".woocommerce-Price-amount");
    const VATPercentage = 0.2; // 20% VAT

    // Check if there's a saved preference in sessionStorage
    let showPricesWithVAT = sessionStorage.getItem('showPricesWithVAT') !== 'false';

    if (!VATToggleEl || !priceAmountEls.length) return;

    // Set initial toggle state from session or default to true
    VATToggleEl.checked = showPricesWithVAT;
    
    // Store original VAT-included prices and initialize display
    storeOriginalPrices();
    updateAllPriceDisplays();

    // Add event listener for toggle changes
    VATToggleEl.addEventListener("change", function () {
        showPricesWithVAT = VATToggleEl.checked;
        // Save preference to sessionStorage
        sessionStorage.setItem('showPricesWithVAT', showPricesWithVAT);
        
        // Also save to cookie so PHP can access it on the next page load
        setCookie('showPricesWithVAT', showPricesWithVAT);
        
        updateAllPriceDisplays();
    });

    // Helper function to set cookies
    function setCookie(name, value) {
        // No expiration date means the cookie expires when the browser session ends
        document.cookie = name + "=" + value + "; path=/";
    }

    function storeOriginalPrices() {
        priceAmountEls.forEach(function(priceEl) {
            storeOriginalPrice(priceEl);
        });
    }

    function storeOriginalPrice(displayElement) {
        const priceText = displayElement.textContent.trim();
        const price = parseFloat(priceText.replace(/[^0-9.-]+/g, ""));
        const currencySymbol = priceText.replace(/[\d.,]+/g, "").trim();
        const isCurrencyBefore = priceText.startsWith(currencySymbol);
        
        // Store original values as data attributes
        displayElement.dataset.originalPrice = price;
        displayElement.dataset.currencySymbol = currencySymbol;
        displayElement.dataset.isCurrencyBefore = isCurrencyBefore;
    }

    function updateAllPriceDisplays() {
        priceAmountEls.forEach(updatePriceDisplay);
        
        // Update VAT status elements
        const vatStatusEls = document.querySelectorAll(".vat-status");
        vatStatusEls.forEach(function(el) {
            el.textContent = showPricesWithVAT ? "(inc. VAT)" : "(ex. VAT)";
        });
    }

    function updatePriceDisplay(displayElement) {
        let originalPrice, currencySymbol, isCurrencyBefore;
        
        // Check if dataset is available
        if (displayElement.dataset.originalPrice) {
            originalPrice = parseFloat(displayElement.dataset.originalPrice);
            currencySymbol = displayElement.dataset.currencySymbol;
            isCurrencyBefore = displayElement.dataset.isCurrencyBefore === "true";
        } else {
            // Fallback to parsing the HTML structure
            const currencyElement = displayElement.querySelector(".woocommerce-Price-currencySymbol");
            if (currencyElement) {
                currencySymbol = currencyElement.textContent;
                const priceText = displayElement.textContent.trim();
                originalPrice = parseFloat(priceText.replace(/[^0-9.-]+/g, ""));
                isCurrencyBefore = priceText.indexOf(currencySymbol) === 0;
            } else {
                // Default fallback
                const priceText = displayElement.textContent.trim();
                originalPrice = parseFloat(priceText.replace(/[^0-9.-]+/g, ""));
                currencySymbol = priceText.replace(/[\d.,]+/g, "").trim();
                isCurrencyBefore = priceText.startsWith(currencySymbol);
            }
        }
        
        const newPrice = calculatePrice(originalPrice);
        
        // If the element has currency as a child element, update it differently
        const currencyElement = displayElement.querySelector(".woocommerce-Price-currencySymbol");
        if (currencyElement) {
            // First, clone the currency element to preserve it
            const clonedCurrency = currencyElement.cloneNode(true);
            
            // Clear all content from the parent element
            displayElement.innerHTML = '';
            
            // Add currency and price back in the correct order
            if (isCurrencyBefore) {
                displayElement.appendChild(clonedCurrency);
                displayElement.appendChild(document.createTextNode(newPrice.toFixed(2)));
            } else {
                displayElement.appendChild(document.createTextNode(newPrice.toFixed(2)));
                displayElement.appendChild(clonedCurrency);
            }
        } else {
            // Use the original formatting approach
            displayElement.textContent = formatPrice(newPrice, currencySymbol, isCurrencyBefore);
        }
    }

    function calculatePrice(price) {
        // All prices start with VAT included
        if (showPricesWithVAT) {
            return price; // Return original VAT included price
        } else {
            // Remove VAT from the price
            return price / (1 + VATPercentage);
        }
    }

    function formatPrice(price, currency, isCurrencyBefore) {
        const formattedPrice = price.toFixed(2);
        return isCurrencyBefore 
            ? currency + formattedPrice 
            : formattedPrice + " " + currency;
    }
});
