/**
 * Mobile Menu functionality with accessibility support
 */
document.addEventListener('DOMContentLoaded', function() {
    const burgerButton = document.getElementById('burger-menu-button');
    const mobileMenu = document.getElementById('mobile-menu');
    const closeButton = document.querySelector('.close-mobile-menu');
    
    if (!burgerButton || !mobileMenu || !closeButton) return;
    
    // Function to open the mobile menu
    function openMobileMenu() {
        // Update attributes and classes
        burgerButton.setAttribute('aria-expanded', 'true');
        mobileMenu.classList.add('is-active');
        mobileMenu.setAttribute('aria-hidden', 'false');
        
        // Set focus within the menu
        setTimeout(() => {
            closeButton.focus();
        }, 100);
        
        // Add event listener to trap focus in modal
        document.addEventListener('keydown', trapFocus);
        
        // Close menu when ESC key is pressed
        document.addEventListener('keydown', handleEscKey);
        
        // Prevent scrolling on body
        document.body.style.overflow = 'hidden';
    }
    
    // Function to close the mobile menu
    function closeMobileMenu() {
        // Update attributes and classes
        burgerButton.setAttribute('aria-expanded', 'false');
        mobileMenu.classList.remove('is-active');
        mobileMenu.setAttribute('aria-hidden', 'true');
        
        // Return focus to burger button
        burgerButton.focus();
        
        // Remove event listeners
        document.removeEventListener('keydown', trapFocus);
        document.removeEventListener('keydown', handleEscKey);
        
        // Re-enable scrolling on body
        document.body.style.overflow = '';
    }
    
    // Function to handle ESC key press
    function handleEscKey(e) {
        if (e.key === 'Escape') {
            closeMobileMenu();
        }
    }
    
    // Function to trap focus within the mobile menu
    function trapFocus(e) {
        // Get all focusable elements in the menu
        const focusableElements = mobileMenu.querySelectorAll(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
        
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];
        
        // If focus moved out of the menu - push it back in
        if (e.key === 'Tab') {
            if (e.shiftKey && document.activeElement === firstElement) {
                e.preventDefault();
                lastElement.focus();
            } else if (!e.shiftKey && document.activeElement === lastElement) {
                e.preventDefault();
                firstElement.focus();
            }
        }
    }
    
    // Event listeners for opening/closing the menu
    burgerButton.addEventListener('click', function() {
        if (this.getAttribute('aria-expanded') === 'false') {
            openMobileMenu();
        } else {
            closeMobileMenu();
        }
    });
    
    closeButton.addEventListener('click', closeMobileMenu);
    
    // Close menu when clicking outside
    document.addEventListener('click', function(e) {
        const isMenuOpen = mobileMenu.classList.contains('is-active');
        const clickedInsideMenu = mobileMenu.contains(e.target);
        const clickedOnBurger = burgerButton.contains(e.target);
        
        if (isMenuOpen && !clickedInsideMenu && !clickedOnBurger) {
            closeMobileMenu();
        }
    });

    // Sub-menu toggle functionality
    const parentMenuItems = mobileMenu.querySelectorAll('.menu-item-has-children > a');
    
    // Add accessibility attributes to parent menu items
    parentMenuItems.forEach(menuItem => {
        const subMenu = menuItem.nextElementSibling;
        if (subMenu && subMenu.classList.contains('sub-menu')) {
            // Set aria attributes for the parent menu item
            menuItem.setAttribute('aria-expanded', 'false');
            menuItem.setAttribute('aria-controls', `submenu-${generateUniqueId()}`);
            
            // Set ID and aria attributes for submenu
            const submenuId = menuItem.getAttribute('aria-controls');
            subMenu.setAttribute('id', submenuId);
            subMenu.setAttribute('aria-hidden', 'true');
            
            // Add click event to toggle submenu visibility
            menuItem.addEventListener('click', function(e) {
                e.preventDefault();
                
                const isExpanded = this.getAttribute('aria-expanded') === 'true';
                
                // Toggle aria-expanded state
                this.setAttribute('aria-expanded', isExpanded ? 'false' : 'true');
                
                // Toggle submenu visibility
                subMenu.classList.toggle('open');
                subMenu.setAttribute('aria-hidden', isExpanded ? 'true' : 'false');
                
                // Toggle parent item class
                this.parentElement.classList.toggle('submenu-open');
            });
        }
    });
    
    // Helper function to generate unique IDs for submenus
    function generateUniqueId() {
        return 'submenu-' + Math.random().toString(36).substr(2, 9);
    }
});
