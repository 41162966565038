/**
 * Accessible Footer Accordion Functionality
 */
(function() {
    document.addEventListener('DOMContentLoaded', function() {
        // Only apply accordion functionality on mobile
        const isMobile = () => window.innerWidth < 768;
        
        // Setup accordion functionality
        function setupAccordions() {
            if (!isMobile()) return;
            
            const toggles = document.querySelectorAll('.footer-accordion-toggle');
            
            toggles.forEach(toggle => {
                const contentId = toggle.getAttribute('aria-controls');
                const content = document.getElementById(contentId);
                
                if (!content) return;
                
                // Set initial state
                toggle.setAttribute('aria-expanded', 'false');
                content.style.display = 'none';
                
                // Remove existing event listeners
                toggle.removeEventListener('click', handleClick);
                toggle.removeEventListener('keydown', handleKeyDown);
                
                // Add event listeners
                toggle.addEventListener('click', handleClick);
                toggle.addEventListener('keydown', handleKeyDown);
            });
        }
        
        // Handle click events
        function handleClick(event) {
            const toggle = event.currentTarget;
            toggleAccordion(toggle);
        }
        
        // Handle keyboard events
        function handleKeyDown(event) {
            const toggle = event.currentTarget;
            
            // Handle Enter or Space key
            if (event.key === 'Enter' || event.key === ' ' || event.key === 'Spacebar') {
                event.preventDefault();
                toggleAccordion(toggle);
            }
            
            // Handle arrow keys for improved accessibility
            if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                event.preventDefault();
                
                const toggles = Array.from(document.querySelectorAll('.footer-accordion-toggle'));
                const currentIndex = toggles.indexOf(toggle);
                
                if (event.key === 'ArrowDown' && currentIndex < toggles.length - 1) {
                    toggles[currentIndex + 1].focus();
                } else if (event.key === 'ArrowUp' && currentIndex > 0) {
                    toggles[currentIndex - 1].focus();
                }
            }
            
            // Handle Home and End keys
            if (event.key === 'Home') {
                event.preventDefault();
                const firstToggle = document.querySelector('.footer-accordion-toggle');
                if (firstToggle) firstToggle.focus();
            }
            
            if (event.key === 'End') {
                event.preventDefault();
                const toggles = document.querySelectorAll('.footer-accordion-toggle');
                const lastToggle = toggles[toggles.length - 1];
                if (lastToggle) lastToggle.focus();
            }
        }
        
        // Toggle accordion state
        function toggleAccordion(toggle) {
            const contentId = toggle.getAttribute('aria-controls');
            const content = document.getElementById(contentId);
            
            if (!content) return;
            
            const isExpanded = toggle.getAttribute('aria-expanded') === 'true';
            
            // Toggle state
            toggle.setAttribute('aria-expanded', !isExpanded);
            toggle.classList.toggle('active', !isExpanded);
            content.classList.toggle('active', !isExpanded);
            
            // Toggle display
            content.style.display = isExpanded ? 'none' : 'block';
        }
        
        // Initialize accordions
        setupAccordions();
        
        // Handle resize events
        let resizeTimer;
        window.addEventListener('resize', function() {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function() {
                const toggles = document.querySelectorAll('.footer-accordion-toggle');
                const contents = document.querySelectorAll('.footer-accordion-content');
                
                if (!isMobile()) {
                    // Desktop view: show all content
                    toggles.forEach(toggle => {
                        toggle.classList.remove('active');
                        toggle.setAttribute('aria-expanded', 'true');
                    });
                    
                    contents.forEach(content => {
                        content.style.display = '';
                        content.classList.remove('active');
                    });
                } else {
                    // Mobile view: setup accordions
                    setupAccordions();
                }
            }, 250); // Debounce resize event
        });
    });
})();
